import React, { useEffect, useState } from "react";
import { Parallax } from 'react-parallax';
import '../css-styles/BackGround.scss';
import Image from '../images/images aryana/Oriental photo.webp';
import ImageSmall from '../images/images aryana/Oriental photo.webp';

const BackGround = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 576);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const divHeight = isMobile ? 300 : 700; // Hauteur ajustée en fonction du mode mobile

  return (
    <div className="background-container">
      <Parallax bgImage={isMobile ? ImageSmall : Image} strength={300}>
        <img src={isMobile ? ImageSmall : Image} alt="Couple de mariées se tenant la main - noir et blanc" style={{ display: 'none' }} />
        <div style={{ height: divHeight}}>
          {/* Contenu pour l'effet de parallaxe */}
        </div>
      </Parallax>
    </div>
  );
};

export default BackGround;