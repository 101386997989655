import React from 'react';
import Image from '../images/images aryana/lampe a huile.webp';
import ImageSmall from '../images/salleshootingdolcevita-sarahduguephotographe4_small.webp';
import '../css-styles/association.scss';
import Image2 from '../images/images page/souplesse exercice yoga.webp';
import BackGround from './BackGround';
import FindMe from './FindMe';
import Activites from './Activites';

const Association = () => {
  


  return (
    <div className='designer-wrapper'>
      <h2 className='element-title'>L'association Diwali</h2>
      <div className='designer-container'>
      <div className='designer-text'>
                    <p>En sanscrit, Diwali veut dire littéralement : lampe à huile, (dipawali) et fait référence à la très populaire fête des lumières en Inde.</p>
                    <p>Elle symbolise la recherche de la lumière en soi, le rayonnement de notre potentiel.</p>
                    <p>Nous vous invitons à allumer cette lumière intérieure, à éveiller votre conscience et à découvrir votre potentiel véritable grâce à la pratique du yoga.</p>
                </div>
        <div className='photo-container'>
        <img src= {Image} className='image-element-yoga'/>
        </div>
      </div>
    </div>
  );
}

export default Association;