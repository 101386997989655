import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const citymap = {
  guenrouet: {
    center: { lat: 47.5285, lng: -1.9461 },
  },
  plesse: {
    center: { lat: 47.5634, lng: -1.8547 },
  },
  redon: {
    center: { lat: 47.6521, lng: -2.0851 },
  },
  montfortSurMeu: {
    center: { lat: 48.1378, lng: -1.9548 },
  },
};

const containerStyle = {
  width: '100%',
  maxWidth: '800px',
  height: '380px',
};

const center = {
  lat: 47.6521, // Latitude de Redon
  lng: -2.0851  // Longitude de Redon
};

const MyMapComponent = () => {
  return (
    <LoadScript
      googleMapsApiKey="AIzaSyC-PYXRuCwxqlV5OW-hUNn4qwFAf_NEcn0"
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={8}
        mapTypeId={"terrain"}
      >
        {Object.keys(citymap).map(city => (
          <Marker 
            key={city}
            position={citymap[city].center}
          />
        ))}
      </GoogleMap>
    </LoadScript>
  );
}

export default React.memo(MyMapComponent);