import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import '../css-styles/Title.scss';



const Title = () => {

    return (
        
        <div className='title-wrapper'>
            
            <h1 className='title-main'>Diwali Yoga</h1>
            {/*<p className='title-second'>Illuminez votre chemin intérieur, là où chaque souffle éclaire votre esprit</p>*/}
            <Link to="/Pratiquer"><button>Prendre un cours</button></Link>
            
        </div>    
      );
}

export default Title;