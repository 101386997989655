import React from 'react';
import Header from '../components/Header';
import '../css-styles/RealisationTantra.scss';
import Footer from '../components/Footer';
import Imagekun from '../images/images aryana/abstract, minimalist illustration of a meditative figure.webp';

import ImageStages from '../images/images aryana/schema.png'; // Example Image for Eight Stages of Yoga
import ImageYogaPractice from '../images/images aryana/peaceful illustration representing the practice of yoga.webp'; // Example Image for Yoga Practice

import Image3 from '../images/images aryana/kundalini cours tahiti 1.webp';
import Image4 from '../images/images aryana/kundalini cours 2.webp';
import Image5 from '../images/images aryana/kundalini_cours_3.webp';

import { FaHeart, FaSeedling, FaUsers } from 'react-icons/fa';

const RealisationTantra = () => {
  return (
    <div className='global-text-wrapper'>
      <h1>Expérimenter la voie du Tantra</h1>
      <div className='text-wrapper'>
        <h2>Le tantra, voie de réalisation</h2>
        <p>Dans une vision orientale, l’homme en tant qu’être est un tout et on ne peut séparer les plans physique, psychologique et spirituel, qui sont les manifestations d’une seule et même réalité : l’énergie.</p>
        <p>Le yoga est donc l‘unification des trois niveaux de l’être individuel que nous venons de voir avec l’Etre cosmique. Par les différentes techniques proposées, l'harmonisation va passer par une maîtrise du corps et du mental afin d’arriver à une expansion de la conscience individuelle. Cette transformation va libérer la conscience du carcan physique et mental pour lever  le voile de l'illusion appelé “Maya” en Inde. Sortie du petit “Moi” la conscience individuelle centrée sur l’égo, peut alors s’unir à la conscience élargie du “Soi, c’est à dire l’Etre cosmique présent en chacun. Le yoga peut se traduire par l’unité de l’être humain avec sa nature divine, lui donnant accès à une supra-conscience lui donnant un meilleur accomplissement de son potentiel. Le but du yoga est donc la libération des conditionnements limitants afin de jouir d’un mode de vie plus serein et de plus en plus épanouissant.</p>
        
        <div className="image-wrapper">
          <img src={Imagekun} alt="Kundalini Rising" />
        </div>
        
        <p>Cette philosophie tantrique ne devient réelle qu’en en faisant l’expérience.  En unissant les deux pôles en soi que sont Shiva représentant la conscience, l’observateur immobile, immuable et éternel avec Shakti, l’énergie créatrice représentant notre nature changeante. Et cette unification se fait grâce à l’énergie vitale appelée “ Kundalini”. Dénommée boucle de la bien-aimée ou parfois puissance du serpent, cette énergie s’éveille par la pratique du corps et de la conscience. Tous les types de yogas visent à éveiller la kundalini avec différentes méthodes.  Le Yoga Kundalini va travailler particulièrement sur la conscience de manière très progressive et particulière pour chaque individu.</p>
        
        <h3>Le kundalini est issu de la tradition tantrique, dont le principe de base est que tout est relié.<br></br> Selon cette philosophie hindouiste, l’univers tout entier vit en chaque individu, chacun étant une petite partie du grand tout.</h3>
        
        <div className='module-text-wrapper'>
            <div className='module-text'>
            <div className='icon'>
              <FaHeart /> {/* Icon above the text */}
            </div>
                <h4>Le but du tantra ?</h4>
                <p>Faire émerger l’énergie des centres énergétiques concentrant les désirs les plus terrestres et égocentriques (triangle composé de la survie, de l’instinct de reproduction de l’espèce et de la satisfaction personnelle du petit moi), vers les centres supérieurs ( l’expression de soi dans la créativité, la vision juste de la réalité et la connexion à plus grand que soi) via le Centre de nous même : le coeur, qui ouvre à l’amour et au Soi.</p>
            </div>
        
            <div className='module-text2'>
            <div className='icon'>
              <FaSeedling /> {/* Icon for serpent */}
            </div>
                <h4>Le serpent</h4>
                <p>Bien connue dans la tradition orientale, la représentation du serpent endormi symbolise l’énergie dont on parle. C’est une vérité profonde et partagée depuis la nuit des temps par la Tradition orientale. Appelée “Kunda” l’énergie est dite enroulée sur elle-même. Quand ce serpent, symbole de la conscience, s’éveille, il monte à travers les sept chakras (centres énergétiques) pour amener à un état supérieur de l’être.</p>
                <p>Les postures (asanas), les exercices de respiration (pranayamas), la méditation et les mantras chantés - en gurmukhi, la langue des sikhs au Pendjab dont Yogi Bhajan était originaire, participent à cette ascension de l’énergie.</p>
            </div>
         
            <div className='module-text'>
            <div className='icon'>
              <FaUsers /> {/* Icon for group yoga */}
            </div>
                <h4>Un yoga de groupe</h4>
                <p>Le kundalini est une expérience qui se partage encore mieux en groupe. L’énergie collective est plus forte que la somme des énergies individuelles et crée une osmose. Les effets sur le système nerveux sont forts, rapides. C’est la raison pour laquelle il est nécessaire de choisir soigneusement son professeur avec laquelle vous vous sentez en affinité.</p>
            </div>
        </div>
        
        <div className='text-wrapper'>
        <h2>Le Tantra « Blanc » quintessence du Kundalini</h2>
        
        {/* Text and Image side by side layout for each section */}
        <div className="section-with-image1">
          <div className="text-content">
            <h3>Le Kundalini Yoga</h3>
            <p>LE KUNDALINI YOGA est une technologie,<br>
            </br> terme donné par Yogi Bhajan, qui permet de changer et de renforcer son rayonnement pour que la vie prenne toute son expansion et que nos capacités puissent s’épanouir.</p>
          </div>
          <div className="image-content">
            {/* <img src={ImageKundaliniYoga} alt="Kundalini Yoga" />*/}
          </div>
        </div>

        <div className="section-with-image">
          <div className="text-content">
            <h3>Les Huit Étapes du Yoga</h3>
            <p>Au IIe siècle avant J-C, le grand philosophe hindou Patanjali a créé les huit étapes du yoga appelé Ashtanga, applicable aussi au Kundalini Yoga :</p>
            <ul>
              <li>Yama (règles de conduite ou éthique)</li>
              <li>Niyama (l’autodiscipline)</li>
              <li>Asana (posture corporelle)</li>
              <li>Pranayama (technique de respiration)</li>
              <li>Pratyahara (maîtrise des sens)</li>
              <li>Dharana (concentration)</li>
              <li>Dhyana (méditation)</li>
              <li>Samadhi (relaxation et illumination)</li>
            </ul>
          </div>
          <div className="image-content">
            <img src={ImageStages} alt="Eight Stages of Yoga" />
          </div>
        </div>

        <div className="section-with-image">
          <div className="text-content">
            <h3>Pratique du Yoga</h3>
            <p>Le Yoga invite à l’auto-discipline. La pratique est optimale lorsqu’elle se fait pendant les heures ambrosiales du petit matin lors de la “sadhana”. Chaque sadhana du matin est vécue comme une victoire sur les résistances habituelles.</p>
          </div>
          <div className="image-content">
            <img src={ImageYogaPractice} alt="Yoga Practice" />
          </div>
        </div>

        <div className="section-with-image">
          <div className="text-content">
            <h3>Le Tantra Yoga</h3>
            <p>C’est le Kundalini Yoga avec le parfum de la méditation. Il se décompose en 5 phases : Le kriya court, les mantras, la relaxation, la méditation chantée, et le silence intérieur.</p>
          </div>
          <div className="image-content">
            {/*<img src={ImageTantra} alt="Tantra Yoga" />*/}
          </div>
        </div>
        </div>

        <h3>Les outils de transformation</h3>
        <p>Par le Yoga. Par la recherche d’ajustement permanent, vont s’engager les 4 plans de conscience que sont le physique, le psychique, l’énergétique et le subtil.</p>
        <p>Notre société moderne valorise le mental, les processus intellectuels, la pensée rationnelle et analytique. En cultivant sa “posture” corporelle et psychique, l'élève va apprivoiser ses ressentis au fur et à mesure du temps et petit à petit, subtilement puis tangiblement, connecter avec cette énergie incroyable qui est notre essence. Elle se trouve au-delà des peurs et des croyances négatives de notre mental. Le kundalini yoga aide à faire le chemin vers soi et à se reconnecter à cette force. Pas seulement au travers d’une expérience méditative passagère, mais aussi par un travail profond sur soi.</p>
        
        <h3>Les quatre niveaux de l'Etre</h3>
        <ul>
          <li>Restructuration physique : Bien-être du corps, Ouverture, souplesse, amplitude des articulations, Postures (colonne/action) se tenir mieux, Cuirasses, blocages du corps.</li>
          <li>Restructuration au niveau du Mental : Concentration, Sérénité mentale et méditation.</li>
          <li>Restructuration au niveau énergétique : Dynamisation et tempérance, Dynamisation au stress, Meilleure endurance, Rayonnement intérieur.</li>
          <li>Restructuration au niveau subtil (Spirituellement) : Meilleure connaissance de Soi, Alignement, plus de qualité d'empathie, ouverture du cœur.</li>
        </ul>
        
        <h3>Par la Thérapie holistique</h3>
        <p>Méthodes d'accompagnement</p>
        
        <h2>Une voie d'éveil</h2>
        <p>Amour / Conscience / Liberté / Créativité / Humilité</p>
        <p>Ce sont les piliers de l'enseignement de l’Ecole du Tantra et par cette voie du cœur proposée par Jean-Jacques Rigou, son fondateur. Cultiver l’amour sous toutes ses formes, autant pour les autres que pour soi, dans l'équilibre du donner-recevoir pour alimenter sa conscience par l’expérience et la connaissance acquise. C’est cette liberté qui est au bout, libre d’être soi-même, libre car débarrassée de nos fonctionnements inconscients qui nous rendent aveugles, obstinés finalement égocentrés. Avec cette vision du cœur, alors la liberté d'Etre soi nous amène à notre créativité dans toutes ses dimensions de la vie. Grandir, évoluer, c’est aller vers l’humilité. En gardant bien l’ancrage dans la réalité, n'oublions pas que rien ne nous appartient. Il s’agit du grand jeu de la vie, dans lequel nous dansons…</p>
        
        <blockquote>« Se connecter à la source de l’intelligence universelle, à l’unité, à l’essence de la création, le but étant de vivre pleinement son expérience créative dans un état méditatif et de s’ouvrir au potentiel du cœur…” - Anand Sharabi (Jean-Jacques Rigou)</blockquote>
        
        <blockquote>« Notre corps est aussi un corps vibratoire. Ce corps est comme un instrument de musique. Ce que vous entendez dépend de votre façon de jouer ! » - Mâ Ananda Moyi</blockquote>
      </div>
    </div>
  );
}

export default RealisationTantra;