import React from 'react';
import '../css-styles/BodyHome.scss';
import MarineBio from './MarineBio';



import Title from './Title';
import DescriptionKundalini from './DescriptionKundalini';
import PreviewFormules from './PreviewFormules';
import PdfLink from './PdfLink';
import Association from './association';
import TantraYoga from './TantraYoga';
import Activites from './Activites';
import FindMe from './FindMe';
import BackGround from './BackGround';
import Enseignant from './Enseignant';
import Ecole from './Ecole';


const BodyHome = () => {
  
    return (
      <div>
            <Association />
            <Activites />
            <BackGround/>
            <FindMe/>
            <Enseignant />
            <Ecole />    
        </div>
    );
}

export default BodyHome;
