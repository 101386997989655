import React from 'react';
import '../css-styles/PratiquerLeYoga.scss';
import Header from "../components/Header";
import CalendarPage from '../components/CalendarPage';
import ImageSalleGuen1 from '../images/images aryana/Guenrouet Salle1.jpg';
import ImageSalleGuen2 from '../images/images aryana/salle culturelle guenrouet al.png';
import ImageSalleGuen3 from '../images/images aryana/salle culturelle guenrouet al 2.png';
import ImageSalleBreteil1 from '../images/images aryana/breteilSalle1.jpeg';
import ImageSalleBreteil2 from '../images/images aryana/breteilSalle2.jpeg';
import ImageSalleBreteil3 from '../images/images aryana/Carine Yoga.jpeg';



const PratiquerLeYoga = () => {
  return (
    <div className="home" >
      <div className="home-container">
        <Header />
        <div className="yoga-page-container">
          <h1>Pratiquer le Yoga</h1>

          <section className="course-info">
            <h2>Calendrier des cours</h2>
            <div className="schedule">
              <CalendarPage />
              <div className='Prices'>
                <h2>Les tarifs</h2>
                <p>Année 2024/2025</p>
                  <ul>
                    <li><strong>Tarif de base des cours:</strong> 15€</li>
                    <li><strong>Tarif annuel:</strong> 305€ (possibilité de régler en 3x)</li>
                    <li><strong>Tarif trimestre 1:</strong> 125€ (14 cours)</li>
                    <li><strong>Tarif trimestre 2:</strong> 100€ (11 cours)</li>
                    <li><strong>Tarif trimestre 3:</strong> 80€ (10 cours)</li>
                    <li><strong>Carte de 10 cours:</strong> 125€</li>
                    <li><strong>Séance individuelle par visio:</strong> 15€</li>
                    <li><strong>Séance en ZOOM:</strong> 15€</li>
                    <li><strong>Cours en audio:</strong> 15€</li>
                  </ul>
                <a href="https://www.helloasso.com/associations/diwali-yoga" className="register-link">Voir les règlements via Hello Asso</a>
              </div>
            </div>
          </section>

          <section className="locations">
            <h2>Présentation des Lieux de Pratique</h2>
            <div className="location-item">

              <div className="salle-guenrouet">
                <h3>Salle de Guenrouët</h3>
                <p><strong>Anne-Laure:</strong> Guenrouët, Plessé, Redon</p>
              <div className="image-gallery">
                <img src={ImageSalleGuen1} alt="Salle Guenrouët 1" className="image-large"/>
                <img src={ImageSalleGuen2} alt="Salle Guenrouët 2" className="image-small"/>
                <img src={ImageSalleGuen3} alt="Salle Guenrouët 3" className="image-small"/>
              </div>
              </div>

              <div className="salle-breteil">
                <h3>Salle de Bréteil</h3>
                <p><strong>Carine:</strong> Breteil, le mardi de 18h30 à 20h (Salle 12)</p>
                <div className="image-gallery">
                  <img src={ImageSalleBreteil1} alt="Salle breteil 1" className="image-large"/>
                  <img src={ImageSalleBreteil2} alt="Salle breteil 2" className="image-small"/>
                  <img src={ImageSalleBreteil3} alt="Photo de carine" className="image-small"/>   
                </div>
              </div>

              <p><strong>Marie:</strong> Guenrouët, le mardi de 14h15 à 15h45</p>
            </div>

            <div className="location-item">
              <h3>Tahiti</h3>
              <p><strong>Aurélie:</strong> Papeete - Tahiti, le mercredi de 17h30 à 19h (Salle des Arpèges)</p>
            </div>
          </section>

         

          <section className="yoga-practice">
            <h2>Présentation du Yoga Kundalini</h2>
            <p>
              Le yoga Kundalini se distingue par ses postures précises, structurant et engageant le corps et l'esprit. Les effets sur le corps sont rapides et notables, comme l'amélioration de la souplesse de la colonne vertébrale et le renforcement musculaire. Chaque séance est thématique et minutée, avec des postures qui stimulent la conscience et l'énergie vitale.
            </p>
            <h3>Recommandations au préalable</h3>
            <ul>
              <li>Venir l’estomac vide et boire de l'eau avant la séance.</li>
              <li>Apporter une tenue souple en fibres naturelles, si possible blanche ou claire.</li>
              <li>Ne forcez pas sur votre corps, respectez vos propres limites.</li>
              <li>Arrivez en avance pour vous relaxer avant le cours.</li>
              <li>Si vous ressentez des sensations anormales, parlez-en au professeur.</li>
            </ul>
          </section>
        </div>
      </div>
    </div>
  );
}

export default PratiquerLeYoga;