import React from 'react';
import Image1 from '../images/images aryana/AnneLaure.jpg';
import Image2 from '../images/images aryana/marie.jpg';
import Image3 from '../images/images aryana/aurelie.jpeg';
import Image4 from '../images/images aryana/carine.jpeg';
import BackgroundImage from '../images/images aryana/INDIAN TEXT.webp';
import '../css-styles/Enseignant.scss'; // SCSS mis à jour

const Enseignant = () => {
  return (
    <div className="teacher-gallery">
      <h2 className="gallery-title">Nos enseignants</h2>
      <div className="teacher-cards">
        <div className="teacher-card">
          <img className="teacher-photo" src={Image1} alt="Anne-Laure" />
          <p className="teacher-name">Anne-Laure</p>
        </div>
        <div className="teacher-card">
          <img className="teacher-photo" src={Image2} alt="Marie" />
          <p className="teacher-name">Marie</p>
        </div>
        <div className="teacher-card">
          <img className="teacher-photo" src={Image3} alt="Aurélie" />
          <p className="teacher-name">Aurélie</p>
        </div>
        <div className="teacher-card">
          <img className="teacher-photo" src={Image4} alt="Carine" />
          <p className="teacher-name">Carine</p>
        </div>
      </div>
    </div>
  );
}

export default Enseignant;