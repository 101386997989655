import React from "react";
import Header from "../components/Header.js";
import "../css-styles/Apropos.scss";
import "../css-styles/Home.scss";
import image from "../images/marinephilippeweddingdesigner-morganecaullephotographie.jpeg";
import MyMapComponent from "../components/MyMapComponent.js";
import Footer from "../components/Footer.js";
import { Helmet } from "react-helmet";

const Apropos = () => {
  return (
    <div className="home">
      <div className="home-container">
        <Helmet>
          <title>A propos de wedding designer</title>
          <meta
            name="description"
            content="."
          />
          <link rel="canonical" href="https://diwali-yoga.fr/Apropos" />
        </Helmet>
        <Header />

        <div className="Background-about">
          <h1> L'association diwali</h1>
          <h2>
            Association de yoga
          </h2>
          <div className="about-wrapper">
            <div className="about-container">
              <div className="Bio-text">
                <p>
                   !
                </p>
                <p>
                  .
                </p>
                <p>
                  {" "}
                  
                </p>
              </div>
              <div className="findMe-text">
                <h3 className="element-title">
                  {" "}
                  Ma zone d’intervention en tant que professeur
                </h3>
                <p>
                  Située à Plessé en Loire atlantique, 
                </p>
              </div>
              <MyMapComponent />
            </div>
           
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Apropos;
