import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './css-styles/index.css';
import Home from './pages/Home';
import Contact from './pages/Contact';
import Apropos from './pages/Apropos';
import Error from './pages/Error';
import Blog from './pages/Blog';
import ArticlePage from './pages/ArticlePage';
import Experiment from './pages/Experiment';
import PratiquerLeYoga from './pages/PratiquerLeYoga'; // Importing the new page

createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Error />} />
        <Route path="/La-voie-du-Tantra" element={<Experiment />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Blog" element={<Blog />} />
        <Route path="/Apropos" element={<Apropos />} />
        <Route path="/Pratiquer" element={<PratiquerLeYoga />} /> 
        <Route path="/article-1" element={<ArticlePage />} />
        <Route path="*" element={<Navigate to="/" />} /> 
      </Routes>
    </Router>
  </React.StrictMode>
);
