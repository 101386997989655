import React from 'react';
import "../css-styles/ArticleContent.scss";
import { Link } from 'react-router-dom';

const ArticleContent = () => {
  return (
    <div className="article-content">
      <h1>Le yoga kundalini, c’est quoi ?</h1>
      <h2>Les bases et pratique de ce yoga</h2>
      
    </div>
  );
};

export default ArticleContent;