import React, { useState } from 'react';
import ImageKunda from '../images/icone/kundalini yoga.png';
import ImageKundaGr from '../images/icone/kundalini yoga GR.png';
import ImageTantra from '../images/icone/tantra yoga.png';
import ImageTantraGr from '../images/icone/tantra yoga GR.png';
import ImageSavita from '../images/icone/savita yoga.png';
import ImageSavitaGr from '../images/icone/savita yoga GR.png';
import ImageTherapy from '../images/icone/therapy holistique.png';
import ImageTherapyGr from '../images/icone/therapy holistique GR.png';
import '../css-styles/Activites.scss';

const Activites = () => {
  const [hoveredIcon, setHoveredIcon] = useState(null);

  return (
    <div className='designer-wrapper'>
      <h2 className='element-title'>Les activités</h2>
      <div className='icone-container'>
        <div className='icone-text'>
          <h3>Kundalini yoga</h3>
          <img 
            className='icone' 
            src={hoveredIcon === 'kundalini' ? ImageKundaGr : ImageKunda} 
            onMouseEnter={() => setHoveredIcon('kundalini')}
            onMouseLeave={() => setHoveredIcon(null)}
            alt="Kundalini Yoga Icon"
          />
          <p>Le Kundalini Yoga est une pratique spirituelle et physique visant à éveiller l'énergie vitale à travers des postures, des techniques de respiration, des chants de mantras, et des méditations.</p> 
           <p> Il équilibre les systèmes nerveux et énergétiques, favorisant la santé mentale et la conscience spirituelle. Une séance inclut des exercices dynamiques, une relaxation guidée, et une méditation.</p>
        
        </div>

        <div className='icone-text'>
          <h3>Tantra yoga</h3>
          <img 
            className='icone' 
            src={hoveredIcon === 'therapy' ? ImageTherapyGr : ImageTherapy} 
            onMouseEnter={() => setHoveredIcon('therapy')}
            onMouseLeave={() => setHoveredIcon(null)}
            alt="Holistic Therapy Icon"
          />
          <p>Dans ce Yoga la science des mantras va prendre toute son importance. Sur un mantra particulier se déclinent les 4 phases Danse-relax-méditation-méditation silencieuse.
            <p>Cette alternance d’états va permettre la circulation de l'énergie, puis une détente, une sensation d’harmonie et de douceur afin de rentrer dans son propre silence intérieur. <br>
          </br>Enfin le mental va ralentir. Le Kriya, lui, sera écourté, mais ce seront les mêmes exercices que dans un cours classique.</p></p>
        </div>

        <div className='icone-text'>
          <h3>Savita yoga</h3>
          <img 
            className='icone' 
            src={hoveredIcon === 'savita' ? ImageSavitaGr : ImageSavita} 
            onMouseEnter={() => setHoveredIcon('savita')}
            onMouseLeave={() => setHoveredIcon(null)}
            alt="Savita Yoga Icon"
          />
          <p>Le Savita Yoga est une forme de yoga douce et apaisante qui se concentre sur le bien-être global du pratiquant. Il met l'accent sur des postures simples, la respiration consciente, et la relaxation profonde, permettant ainsi de réduire le stress et d'améliorer la flexibilité sans effort intense.</p> 
           <p>Le Savita Yoga est idéal pour ceux qui cherchent à intégrer une pratique sereine et rééquilibrante dans leur quotidien, favorisant la détente et le bien-être mental et physique.</p>
        </div>

        <div className='icone-text'>
          <h3>Holistic therapy</h3>
          <img 
            className='icone' 
            src={hoveredIcon === 'tantra' ? ImageTantraGr : ImageTantra} 
            onMouseEnter={() => setHoveredIcon('tantra')}
            onMouseLeave={() => setHoveredIcon(null)}
            alt="Tantra Yoga Icon"
          />
          
          <p>La thérapie holistique est une approche de soins qui considère la personne dans sa globalité, en tenant compte non seulement des symptômes physiques, mais aussi des aspects émotionnels, mentaux, et spirituels.</p>
            <p>Elle vise à rétablir l'équilibre et l'harmonie entre le corps, l'esprit, et l'âme en utilisant diverses techniques telles que la méditation, la visualisation, les soins énergétiques.</p>
        </div>
        
      </div>
    </div>
  );
}

export default Activites;