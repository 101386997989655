import React from 'react';
import Header from '../components/Header';
import '../css-styles/Error.scss';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import RealisationTantra from '../components/RealisationTantra';

const Experiment = () => {

  return (
    <div className="home" >
      <div className="home-container">
        <Header />
        <RealisationTantra />
        <Footer />
      </div>
      
    </div>
    
    
    
  );
}

export default Experiment;