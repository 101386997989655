import React from 'react';
import Image1 from '../images/images aryana/chateau laroque.jpg';
import Image2 from '../images/images aryana/ecoledutantra-2892.jpg';
import Image3 from '../images/images aryana/formationKun1.jpg';
import Image4 from '../images/images aryana/formationKun2.jpg';
import Image5 from '../images/images aryana/formationKun3.jpg';


import '../css-styles/Ecole.scss';


const Ecole = () => {

  return (
    <div className='ecole-wrapper'>
      <h2 className='element-title'>Évoluer Ensemble avec l'école du Tantra</h2>
        <div className='image-text'>
          <img className= 'image' src= {Image1}></img>
          <p>Depuis plus de trente ans, l'École du Tantra accueille ceux qui aspirent à évoluer spirituellement et professionnellement. Situé au cœur des vignobles bordelais, à Juillac en Gironde, notre centre vous reçoit dans le cadre paisible et inspirant du Château Laroque.</p>
<p>Nos stages de trois jours, organisés dans cet environnement exceptionnel, sont reconnus dans le cadre de votre formation à l'École du Tantra. Restez connectés à notre calendrier pour ne manquer aucune opportunité de progression.</p>
        </div>
        
        <h2 className='element-title'>Formation en Kundalini Yoga et Tantra Yoga</h2>
        <div className='image-text'>
          <p className='text'>Si vous découvrez une véritable passion pour ces disciplines à travers un cours, nous vous invitons à approfondir votre expérience lors d'un stage d'essai. </p>
          <p>Ce stage vous permettra de mieux comprendre le cheminement vers la certification en tant que professeur. Avant de débuter la formation complète, quatre stages de préformation sont requis, accompagnés d'un soutien thérapeutique tout au long de votre parcours.</p>
          <div className='image-wrapper'>
            <img className= 'imagebloc' src= {Image3}></img>
            <img className= 'imagebloc' src= {Image4}></img>
            <img className= 'imagebloc' src= {Image5}></img>
          </div>
          
        </div>

        <h2 className='element-title'>Formation en Animation</h2>
        <div className='image-text3'>
          <p>Notre programme de formation s'étend sur deux ans et se décline en modules de Kundalini Yoga et de thérapie.<br>
          </br> Chaque année comprend trois stages intensifs, complétés par des enseignements modulaires, la rédaction d'un mémoire de fin de cycle, et un accompagnement personnalisé tout au long de votre parcours.<br>
          </br> Vous progressez à votre propre rythme, avec le soutien de notre équipe dédiée.</p>
        <img className= 'image' src= {Image2}></img>
        </div>

    </div>
  );
}

export default Ecole;