import React from "react";
import Image from "../images/logo_laboinspi_white.webp";
import "../css-styles/Footer.scss";
import MyMapComponent from "../components/MyMapComponent";
import Image1 from "../images/icone/instagram.webp";
import Image2 from "../images/icone/logo-pinterest.webp";
import Image3 from "../images/icone/logo tiktok.webp";

const Footer = () => {
  const instagramLink = "https://www.instagram.com/le.labo.inspi/";

  return (
    <footer className="footer-reserved">
      <div className="logo-info">
        
        <h3 className="rights-reserved">
          {" "}
          © 2024 diwali yoga<br>
          </br> Tout droits reservés
        </h3>
        <div className="credits-container">
          <h4>Crédits Photo :</h4>
        </div>

      </div>
    </footer>
  );
};

export default Footer;