import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import '../css-styles/CalendarPage.scss';

const courseData = {
  "Anne-Laure": [  // Correction du nom ici si nécessaire
    { day: 1, time: "18h30-20h", location: "Plessé" },  // Lundi
    { day: 2, time: "18h30-20h", location: "Guenrouët" },  // Mardi
    { day: 3, time: "19h-20h30", location: "Redon" },  // Mercredi
    { day: 4, time: "10h-11h30", location: "Plessé" },  // Jeudi matin
    { day: 4, time: "19h-20h30", location: "Guenrouët" },  // Jeudi soir
    { day: 5, time: "7h30", location: "en ligne" },  // Vendredi
  ],
  Marie: [
    { day: 2, time: "14h15-15h45", location: "Salle Culturelle, Guenrouët" },  // Mardi après-midi
  ],
  Carine: [
    { day: 2, time: "18h30-20h", location: "Salle 12, Breteil" },  // Mardi soir
  ],
  Aurelie: [
    { day: 3, time: "17h30-19h", location: "Salle des Arpèges, Papeete" },  // Mercredi après-midi
  ]
};

const CalendarPage = () => {
  const [selectedProfessor, setSelectedProfessor] = useState('Anne-Laure');
  const [selectedDate, setSelectedDate] = useState(null); // Stocker la date sélectionnée
  const [selectedCourses, setSelectedCourses] = useState([]); // Stocker les cours correspondant à la date sélectionnée

  const handleProfessorChange = (e) => {
    setSelectedProfessor(e.target.value);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const dayOfWeek = date.getDay();
    const professorSchedule = courseData[selectedProfessor];
    const coursesForSelectedDate = professorSchedule?.filter(course => course.day === dayOfWeek) || [];
    setSelectedCourses(coursesForSelectedDate); // Met à jour les cours correspondant à la date sélectionnée
  };

  const getTileClassName = ({ date, view }) => {
    if (view === 'month') {
      const dayOfWeek = date.getDay();
      const professorSchedule = courseData[selectedProfessor];

      // Vérifier si le professeur a des cours pour ce jour
      if (professorSchedule && professorSchedule.length > 0) {
        const isWorkingDay = professorSchedule.some(course => course.day === dayOfWeek);
        return isWorkingDay ? 'working-day' : null;
      }
    }
    return null;
  };

  return (
    <div className="calendar-container">
      {/* Sélection du professeur */}
      <div className="professor-select">
        <label htmlFor="professor">Choisissez un professeur:</label>
        <select id="professor" value={selectedProfessor} onChange={handleProfessorChange}>
          <option value="Anne-Laure">Anne-Laure</option>
          <option value="Marie">Marie</option>
          <option value="Carine">Carine</option>
          <option value="Aurelie">Aurélie</option>
        </select>
      </div>

      {/* Calendrier */}
      <Calendar
        onChange={handleDateChange}
        value={selectedDate}
        tileClassName={getTileClassName}  // Ajouter une classe pour les jours travaillés
      />

      {/* Informations sur les cours pour la date sélectionnée */}
      <div className="selected-date-info">
        <h3>
          {selectedDate ? `Date sélectionnée: ${selectedDate.toLocaleDateString('fr-FR', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}` : "Sélectionnez une date"}
        </h3>

        <div className='course-wrapper'>
        {selectedCourses.length > 0 ? (
          selectedCourses.map((course, index) => (
            
                <div key={index} className="course-details">
              <p><strong>Professeur:</strong> {selectedProfessor}</p>
              <p><strong>Heure:</strong> {course.time}</p>
              <p><strong>Salle:</strong> {course.location}</p>
            </div>
            
            
          ))
        ) : (
          <p>Aucun cours prévu pour cette date.</p>
        )}
        </div>
      </div>
    </div>
  );
};

export default CalendarPage;