// Header.js
import React from 'react';
import NavigationBar from './NavigationBar';
import '../css-styles/Header.scss';
import { Link } from 'react-router-dom';
import logo from '../images/icone/Diwali Yoga.png';
import BackGround from '../images/images aryana/baniereHeader.jpg';

const Header = () => {
  return (
    <header className="header">
      <div className='header-background'>
        <div className='wrapper-header'>
          <NavigationBar />
        </div>
      </div>
    </header>
  );
}

export default Header;
