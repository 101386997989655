import React from "react";
import Header from "../components/Header.js";
import "../css-styles/Contact.scss";
import "../css-styles/Home.scss";
import MyFormModal from "../components/MyFormModal.js";
import Formulaire from "../components/Formulaire.js";
import image from "../images/images aryana/20210626_141611.jpg";
import Footer from "../components/Footer.js";
import { Helmet } from 'react-helmet';

import Image1 from "../images/icone/instagramBlack.png";
import Image2 from "../images/icone/logo-pinterestBlack.png";
import Image3 from "../images/icone/logo tiktokBlack.png";
import Image4 from "../images/icone/FacebookBlack.png";
import Image5 from "../images/icone/LinkedinBlack.png";


const Contact = () => {
    
  
    return (
        <div className="home">
      <div className="home-container">
      <Helmet>
        <title>Contactez nous</title>
        <meta name="description" content="..." />
        <link rel="canonical" href="https://laboinspi-wedding.fr/contact" />
      </Helmet>
        <Header />
        <h1>Contactez nous</h1>
        <div className="Socialmedia-Wrapper">
          <h2>Vous pouvez aussi nous retrouver sur les réseaux sociaux</h2>
        {/*<div className="social-media-contact">
          <a
            href="https://www.instagram.com/le.labo.inspi/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Instagram"
          >
            <img src={Image1} className="insta-icone" alt="instagram-icone" />
          </a>
          <a
            href={"https://pin.it/5zCeBKD"}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Pinterest"
          >
            <img
              src={Image2}
              className="pinterest-icone"
              alt="pinterest-icone"
            />
          </a>
          <a
            href={"https://www.tiktok.com/@le.labo.inspi"}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Pinterest"
          >
            <img
              src={Image3}
              className="tiktok-icone"
              alt="tiktok-icone"
            />
          </a>
          <a
            href={"https://www.facebook.com/le.labo.inspi"}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Facebook"
          >
            <img
              src={Image4}
              className="facebook-icone"
              alt="Linkedin-icone"
            />
          </a>
          <a
            href={"https://www.linkedin.com/in/marine-philippe-b91328110/"}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Linkedin"
          >
            <img
              src={Image5}
              className="linkedin-icone"
              alt="linkedin-icone"
            />
    
          
        </div></a> */}
        </div>
        <div className="Background-wrapper">
          <img className="image-background" src={image} />
        <div className="formulaire-container">
          
          <h2>Un rendez-vous, une question ou un renseignement ?</h2>
          <Formulaire/>
        </div>
        </div>
        
      </div> 
      < Footer />
      </div>
    );
  }
  
  export default Contact;