import React from 'react';
import '../css-styles/FindMe.scss';
import MyMapComponent from '../components/MyMapComponent';


const FindMe = () => {
  return (
    
      
      <div className='findMe-textMap-wrapper'>
        
         <div className='findMe-text'>
          <h2 className='element-title'>Où nous retrouver ?</h2>
            <p>Diwali vous propose de découvrir nos offres de cours, stage et évènements.<br></br>
            L'association accueille les cours de Kundalini Yoga, Tantra Yoga et Yoga doux entre Nantes (Loire Atlantique 44) et Rennes(l'Ile-et-Vilaine 35).
            </p>
            <p>Guenrouet, Plessé, Redon et à côté de Rennes, Montfort-sur-Meu.</p>
            
        </div>
        
           <MyMapComponent/> 
           
        
      </div>
   
  );
}

export default FindMe;